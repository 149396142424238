import { useState, useContext } from 'react';
import cookie from 'js-cookie';
import { sum } from 'lodash-es';

import {
  Attribute,
  Cart,
  CartLine,
} from '@lambda/commons/apis/shopify/types/storefront';

import { ReebeloStoreT, REEBELO_DOMAINS } from '@lambda/reebelo/src/types';
import GTM from '@/lib/google-tag-manager';
import { AppCtx } from '../pages/_app';
import useShopify from './use-shopify';
import shopifyInstance from './shopify';
import settings from '../settings';
import { SHOPIFY_CART_QUERY } from './cart';
import { logger } from './logger';
import { getCustomerFromCookie } from './customer';
import { analytics } from './analytics/vendor/segment/segment';
import { useBuybackContext } from './buyback/BuybackContext';

const staticShopifyImport = import('./shopify');

const CART_COOKIE = 'cart-id';
const CART_COOKIE_ORDER_COMPLETED = 'cart-id-order-completed';
type CartId = string | null;

const getCartId = (): CartId => {
  if (typeof window === 'undefined') return global.CART_ID_SERVER;
  const cartId = cookie.get(CART_COOKIE);
  const cartIdOrderCompleted = cookie.get(CART_COOKIE_ORDER_COMPLETED);

  if (cartId && cartIdOrderCompleted && cartId === cartIdOrderCompleted) {
    const { store } = settings;

    cookie.remove(CART_COOKIE, { sameSite: 'lax' });
    cookie.remove(CART_COOKIE_ORDER_COMPLETED, {
      path: '/',
      domain: `.${REEBELO_DOMAINS[store as ReebeloStoreT]}`,
    });

    return null;
  }

  return cartId || null;
};

const setCartId = (cartId: CartId) => {
  if (typeof window === 'undefined') {
    global.CART_ID_SERVER = cartId || null;

    return;
  }

  // on dev we remove cookie to prevent error after add to cart when you switching store
  if (cartId == null || cartId === '') {
    if (process.env.NEXT_PUBLIC_STAGE === 'dev')
      cookie.remove(CART_COOKIE, { sameSite: 'lax' });
  } else {
    cookie.set(CART_COOKIE, cartId, { expires: 9.5, sameSite: 'lax' });
  } // Shopify cart expires after 10 days
};

export type CartItemAttribute = Attribute;

export type AddToCartParams = {
  attributes?: Array<CartItemAttribute>;
  /** "gid://shopify/ProductVariant/40476830466197" */
  merchandiseId: string;
  quantity?: number;
};
export type UpdateCartParams = Array<{
  attributes: Array<CartItemAttribute>;
  id: string;
  quantity?: number;
}>;

const getCartCountFromCart = (cart: Cart | null) => {
  if (cart == null) return 0;

  return sum(cart.lines.edges.map((l) => l.node.quantity));
};

export const initializeCart = async (
  setLoadingFunc: (loading: boolean) => void,
): Promise<{
  cart: Cart | null;
  count: number;
}> => {
  // This function is used in header, so we defer the import of shopify to reduce the bundle size
  const currentCartId = getCartId();

  if (currentCartId == null) {
    setLoadingFunc(false);

    return { cart: null, count: 0 };
  }

  setLoadingFunc(true);
  const shopify = await staticShopifyImport;
  const response = await shopify.default
    .fetch({
      query: `query ($id: ID!) {
              cart(id: $id) {
                ${SHOPIFY_CART_QUERY}
              }
            }`,
      variables: { id: currentCartId },
    })
    .catch(() => ({ cart: null }));

  setLoadingFunc(false);
  const { cart } = response;

  if (cart == null) {
    logger.warn({ currentCartId }, 'cart not found from current cart id');

    setCartId(null);

    return { cart: null, count: 0 };
  }

  return {
    cart,
    count: getCartCountFromCart(cart),
  };
};

export const updateCartAttributes = async (
  cartId: string,
  attributes: Array<{ key: string; value: string }>,
) => {
  await shopifyInstance.fetch({
    query: `mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
      cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
        cart { id }
      }
    }`,
    variables: { cartId, attributes },
  });
};

const createCartId = async () => {
  const response = await shopifyInstance.fetch({
    query: `mutation cartCreate($input: CartInput) {
              cartCreate(input:$input) {
                cart { id }
                userErrors { field message }
              }
            }`,
    variables: { input: {} },
  });

  const newCartId = response.cartCreate.cart.id;

  if (!newCartId) logger.error({ response }, 'error creating a new cart id');

  setCartId(newCartId);

  if (newCartId != null && newCartId.includes('/')) {
    const isProd =
      process.env.NEXT_PUBLIC_STAGE === 'prod' || process.env.STAGE === 'prod';

    const customer = await getCustomerFromCookie();
    const reebeloId = customer?.reebeloId;
    const anonymousId = (await analytics.user())?.anonymousId();
    const cartIdNumber = newCartId.split('/').slice(-1)[0];

    logger.info(
      { previousCartId: newCartId, updatedCartId: cartIdNumber },
      'cart id differences',
    );

    const attributes = [
      {
        key: 'cartId',
        value: cartIdNumber,
      },
      {
        key: 'braze_sdk_api_key',
        value: isProd ? settings.braze.api_key : settings.braze.dev_key,
      },
      {
        key: 'dd_app_env',
        value: process.env.NEXT_PUBLIC_STAGE || process.env.STAGE || 'unknown',
      },
      {
        key: 'dd_app_version',
        value:
          process.env.APP_VERSION ||
          process.env.NEXT_PUBLIC_APP_VERSION ||
          'dev',
      },
    ];

    if (reebeloId) {
      attributes.push({
        key: 'braze_user_id',
        value: reebeloId,
      });
    }

    if (anonymousId) {
      attributes.push({
        key: 'segment_anonymous_id',
        value: anonymousId,
      });
    }

    await updateCartAttributes(newCartId, attributes);
  }

  return newCartId;
};

export const encodeDecodeForAttr = {
  encode: ({ sku, title }: { sku: string; title: string }) => [
    {
      key: 'For',
      value: `[${sku}] ${title}`,
    },
  ],
  decode: (attrs: Attribute[]) =>
    /^\[(.*)\] /.exec(attrs.find((a) => a.key === 'For')?.value || '')?.[1],
};

export const generateIDAttr = (vendor: string, sku: string) => [
  {
    key: 'ID',
    value: `${settings.store}#${vendor}#${sku}`,
  },
];

export const generateAddCartDateTimeAttr = (lineItem?: CartLine) => {
  const value = new Date().toISOString();
  const existingValue =
    lineItem?.attributes.find((attr) => attr.key === '_add_cart_date_time')
      ?.value ?? '';

  return [
    {
      key: '_add_cart_date_time',
      value: existingValue !== '' ? existingValue : value,
    },
  ];
};

export const generateCategoryAttr = (category?: string) => {
  if (category == null) return [];

  return [
    {
      key: '_category',
      value: category,
    },
  ];
};

const getOrCreateCartId = () => {
  const response = getCartId() || createCartId();

  return response;
};

type AddCart = Cart | { checkoutUrl: string };

export type AddCartFunc = (
  line: AddToCartParams,
  cartIdParam?: string,
) => Promise<AddCart>;

const useScrollOnItemAdd = () => {
  const [scrollPos, setScrollPos] = useState(0);

  const setScrollPosOnCartChange = () => {
    setScrollPos(window.scrollY);
  };

  return { scrollPos, setScrollPosOnCartChange };
};

export default function useCart() {
  const [loading, fetch] = useShopify();
  const { setCartCount, cartCount, cart, setCart } = useContext(AppCtx);
  const { scrollPos, setScrollPosOnCartChange } = useScrollOnItemAdd();
  const { resetQuote, includesTradeIn } = useBuybackContext();

  const remove = async (lineIds: string[], cartIdParam?: string) => {
    try {
      const cartId = cartIdParam || (await getOrCreateCartId());
      const response = await fetch({
        query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
        variables: { cartId, lineIds },
      });

      const count = getCartCountFromCart(response.cartLinesRemove.cart);

      setCart(response.cartLinesRemove.cart);
      setCartCount(count);
    } catch (error) {
      logger.error({ error }, 'Shopify error removing cart line');
    }
  };

  const add: AddCartFunc = async (line: AddToCartParams, cartIdParam) => {
    try {
      const cartId = cartIdParam || (await getOrCreateCartId());
      const response = await fetch({
        query: `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
                cartLinesAdd(cartId: $cartId, lines: $lines) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
        variables: { cartId, lines: line },
      });

      const count = getCartCountFromCart(response.cartLinesAdd.cart);

      setCart(response.cartLinesAdd.cart);
      setCartCount(count);
      GTM.event('add_to_cart', { items: [{ item_id: line.merchandiseId }] });

      return response.cartLinesAdd.cart;
    } catch (error: any) {
      if (
        error &&
        error.message.toLowerCase().includes('cart does not exist.')
      ) {
        const newCartId = await createCartId();
        const newCart: AddCart = await add(line, newCartId);

        return newCart;
      }

      return {
        checkoutUrl: '',
      };
    }
  };

  const update = async (lines: UpdateCartParams, cartIdParam?: string) => {
    try {
      const cartId = cartIdParam || (await getOrCreateCartId());
      const response = await fetch({
        query: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                cartLinesUpdate(cartId: $cartId, lines: $lines) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
        variables: { cartId, lines },
      });

      const count = getCartCountFromCart(response.cartLinesUpdate.cart);

      setCart(response.cartLinesUpdate.cart);
      setCartCount(count);
    } catch (error) {
      logger.error({ error }, 'Shopify error updating cart line');
    }
  };

  const getCartById = async (cartId: string) => {
    const response = await fetch({
      query: `query ($id: ID!) {
              cart(id: $id) {
                ${SHOPIFY_CART_QUERY}
              }
            }`,
      variables: { id: cartId },
    });

    return response.cart;
  };

  const clearCart = () => {
    if (includesTradeIn) resetQuote();
    setCart(null);
    setCartCount(0);
    cookie.remove(CART_COOKIE, { sameSite: 'lax' });
    cookie.remove(CART_COOKIE_ORDER_COMPLETED, { sameSite: 'lax' });
  };

  return {
    add,
    cart,
    update,
    remove,
    loading,
    cartCount,
    scrollPos,
    createCartId,
    getOrCreateCartId,
    setScrollPosOnCartChange,
    setCart,
    getCartById,
    clearCart,
  };
}
